html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #fff !important;
  // font-family: sans-serif !important;
  font-family: Arial, Helvetica, sans-serif !important;
  // font-family: "Orkney", sans-serif !important;
  // font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
// varbackground: #15243a;

:root {
  /* --primaryColor:#222967; */

  --primaryColor: #15243a;
  --form_bg_color: white;
  --color-dark: #252525;
  --color-white: #ffffff;
  --color-gray: #d9d9d9;
  --color-blue-dark: #363070;
  --color-green: #5baa8b;
  --color-mehndi: #ceb745;
  --color-green-light: #8cc6c7;
  --color-pink: #e59a94;
  --color-pink-light: #efe3e3;
  --color-medium-pink: #f3b5c0;
  --color-dark-orange: #d08e2c;
  --color-dark-yellow: #e1a63c;
  --color-blue: #295ef5;
  --color-deep-orange: #ea6a45;
  --color-icon: #959595;
  --bg-quote-color: #f8f8f8;
  --bg-uploads: #f3f6ff;
}
// font family: Cormorant
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap");
// font-family: "Cormorant", serif;

// font family: Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// font-family: "Montserrat", sans-serif;

// font faily: Manrope
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
// font-family: "Manrope", sans-serif;
.ant-layout-header {
  background-color: var(--primaryColor) !important;
  padding: 0% !important;
  height: 90px !important;
}
.ant-layout-footer {
  padding: 0%;
}

.ant-card-head {
  border-bottom: none !important;
  min-height: 0px !important;
  font-size: 18.1px !important;
  width: 100%;
  margin-top: 10px !important;
  // color: rgba(0, 0, 0, 0.555);
}
.ant-menu-overflow {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.ant-upload.ant-upload-select {
  display: block !important;
  overflow: hidden;
  margin-bottom: 24px;
  img {
    width: 100%;
    height: 100%;
  }
}
div.ant-select-selector {
  border: 1px solid #d9d9d9;
  background-color: transparent !important;
  color: white;
}
.ant-input::placeholder {
  color: white;
}

.dynamic_form_nest_item {
  .ant-input::placeholder {
    color: #aeaeae;
  }
}

.ant-select-selection-placeholder {
  color: white;
}
