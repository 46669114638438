.main-navbar {
  background: linear-gradient(100deg, #39546d 0%, #0a2d4d 25%);
  color: #fff;
  width: 100%;
  display: flex;
  height: 90px;
  justify-content: center;
  position: relative;
  z-index: 1000;

  .container-navber {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  .main-logo {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;

    .navbar-brand {
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        width: 120px;
      }
    }
  }

  .navbar-nav {
    .nav-list {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      gap: 26px;

      li {
        a {
          text-decoration: none;
          display: inline-block;
          font-size: 20px;
          font-weight: 400;
          line-height: 21.38px;
          letter-spacing: 0.03em;
          color: #fff;

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .navbar-actions {
    border-radius: 60px;
    // height: 50px;
    z-index: 999;
    // padding: 1px 1px;
    border: 2px solid #ffffff;
    display: flex;

    .login-in-btn {
      background-color: #5c92cd;
      height: 45px;
      min-width: 100px;
      color: #fff;
      border: none;
      border-radius: 50px;
      font-size: 17px;
    }

    .signup-btn {
      color: #fff;
      border-radius: 50px;
      font-size: 17px;
      border: none;
      background-color: transparent;
    }
    @media (min-width: 450px) {
      .signup-btn {
        height: 45px;
        width: 100px;
      }
    }
  }

  @media (max-width: 1000px) {
    .navbar-nav {
      display: none;
      .nav-list {
        gap: 0;

        li {
          a {
            display: inline-block;
            font-size: 16px;
          }
        }
      }
      &.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        right: 0;
        // background: #0a2d4d;
        width: 100%;
        text-align: center;
        align-items: flex-end;
        z-index: 1000;
      }
      .nav-list {
        background: #0a2d4d;
        width: 40%;
        height: 100vh;
        flex-direction: column;
        margin: 0;
        padding: 0;
        line-height: 64px;
      }
    }

    .menu-icon {
      display: block;
      font-size: 2rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 450px) {
    .navbar-nav {
      display: none;
      .nav-list {
        gap: 0;

        li {
          a {
            display: inline-block;
            font-size: 16px;
          }
        }
      }
      &.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        right: 0;
        // background: #0a2d4d;
        width: 70%;
        text-align: center;
        align-items: flex-end;
        z-index: 1000;

        .nav-list {
          background: #0a2d4d;
          width: 100%;
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 999;
  }

  @media (min-width: 1001px) {
    .menu-icon {
      display: none;
    }
  }
}
