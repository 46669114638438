.Footers {
  // height: 120px;
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff !important;

  .inner-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    margin-bottom: 50px;
  }

  .footer__nav {
    margin: 20px auto;
    display: flex;
    gap: 50px;

    .footer__link {
      list-style: none;
      font-size: 20px;
      font-weight: 400;
      line-height: 21.38px;
      letter-spacing: 0.03em;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .Footers {
    .footer__nav {
      flex-direction: column;
      .footer__link {
        font-size: 16px;
      }
    }
  }
}
